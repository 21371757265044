import { useMainStore } from '~/store/main/main.store'

export function callTimeComposible() {
  const mainStore = useMainStore()
  const timeToCall = ref('')
  const timeToUpdate = ref(true)
  const timeList = computed(
    () =>
      mainStore.getCallSlots?.slots
        .map(el =>
          el.slots.map((e) => {
            return {
              value: {
                day: el.day,
                slot: e,
              },
              text:
                el.human_day.toLowerCase()
                + ' с '
                + new Date(e.start)
                  .toLocaleTimeString('ru-RU')
                  .split(':')
                  .filter((_, i) => i < 2)
                  .join(':')
                  + ' до '
                  + new Date(e.end)
                    .toLocaleTimeString('ru-RU')
                    .split(':')
                    .filter((_, i) => i < 2)
                    .join(':'),
            }
          }),
        )
        .flat(),
  )
  const timeNow = computed(() => {
    return {
      value: {},
      text: mainStore.getCallSlots?.display_right_away
        ? 'в течение 15 минут'
        : 'в рабочее время',
    }
  })
  const callTime = computed(() =>
    timeList.value
    && timeList.value.find(el => el.text === timeToCall.value)
      ? timeList.value!.find(el => el.text === timeToCall.value)!.value
        .slot.start
      : undefined,
  )

  const setTimeToCall = (time) => {
    timeToCall.value = time.text
  }

  const showActiveSortList = (e) => {
    if (e && timeToUpdate.value) {
      mainStore.fetchCallSlots()
      timeToUpdate.value = false
      setTimeout(() => {
        timeToUpdate.value = true
      }, 60000)
    }
  }

  return {
    timeList,
    timeNow,
    timeToCall,
    callTime,
    setTimeToCall,
    showActiveSortList,
  }
}
